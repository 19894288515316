const tabs = ["general", "verification"];

const useProfileData = () => {
	const route = useRoute();
	const isGuest = useIsGuest();
	const referralPopupCookie = useCookie("showReferralPopup");
	const { data: appInitData } = useAppInitData();
	const { data: pageData } = useAsyncFetch({
		path: "/rest/page/profile/",
		method: "get",
		options: {
			cached: true,
			immediate: !isGuest.value,
			watch: [() => isGuest.value]
		}
	});
	const { logoutUser } = useLogout();

	const activeTab = ref(tabs[0]);
	const verificationState = ref(false);

	const verificationDone = computed(() => !!appInitData.value?.verificationDone);
	const name = computed(() => appInitData.value?.name);
	const surname = computed(() => appInitData.value?.surname);
	const email = computed(() => appInitData.value?.email);
	const phone = computed(() => appInitData.value?.phone);
	const hasDeposits = computed(() => appInitData.value?.hasDeposits);
	const isD1User = computed(() => appInitData.value?.depositsCount === 1);
	const isVerificationCompleted = computed(() => appInitData.value?.fraudDetector?.verificationCompleted);
	const isShowVerificationAlarm = computed(
		() => isD1User.value && verificationState.value && !isVerificationCompleted.value
	);
	const additionalVerificationRequired = computed(() => appInitData.value?.accountStatus === "UNCONFIRMED");
	const documentCompleted = computed(() => pageData.value?.payload?.documentConfirmStatus === "verified");

	const documentOnVerification = computed(() =>
		["onVerification", "rejected", "duplicateCredentials"].includes(pageData.value?.payload?.documentConfirmStatus)
	);

	const livenessCheckCompleted = computed(() =>
		["duplicateCredentials", "approved", "verified", "onVerification", "rejected"].includes(
			pageData.value?.payload?.documentConfirmStatus
		)
	);
	const verificationProfileCompleted = computed(
		() => appInitData.value?.profileCompleted && livenessCheckCompleted.value
	);
	const progressActive = computed(() => !appInitData.value?.profileCompleted || !livenessCheckCompleted.value);

	const handleLogout = async () => {
		await logoutUser();
		navigateTo("/", { external: true });
		dispatchGAEvent({ event: "logout", location: "settings" });
		localStorage.removeItem("showedDailyWheelPopup");
		localStorage.removeItem("showReferralPopup");
		localStorage.removeItem("isShowExitPopup");
		localStorage.removeItem("exitPopupTime");
		referralPopupCookie.value = null;
	};

	const hideVerificationAlarm = () => {
		if (isD1User.value) {
			verificationState.value = false;
			localStorage.setItem("showVerificationAlarm", "false");
		}
	};

	const handleChangeTab = (tab: string) => {
		if (tab === "verification") {
			hideVerificationAlarm();
		}
	};

	onMounted(() => {
		if (localStorage.getItem("showVerificationAlarm")) {
			verificationState.value = localStorage.getItem("showVerificationAlarm") === "true";
		} else {
			verificationState.value = true;
			localStorage.setItem("showVerificationAlarm", "true");
		}

		if (route.hash.includes("verify")) {
			activeTab.value = "verification";
			hideVerificationAlarm();
		}
	});

	return {
		activeTab,
		appInitData,
		documentOnVerification,
		progressActive,
		livenessCheckCompleted,
		verificationDone,
		additionalVerificationRequired,
		verificationProfileCompleted,
		tabs,
		pageData,
		name,
		surname,
		email,
		phone,
		hasDeposits,
		documentCompleted,
		isShowVerificationAlarm,
		handleLogout,
		handleChangeTab,
		isVerificationCompleted
	};
};

export default useProfileData;
